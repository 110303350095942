export default function mainNavigation() {
  // Main navigation
  const pageNode = document.querySelector(".page");
  const mastheadNode = document.querySelector("#masthead");
  const mobileNavToggle = document.querySelector("#mobileMenuTrigger");
  const mobileNavToggleA11YText = mobileNavToggle.querySelector(
    ".screen-reader-text"
  );
  /* Open / Close Mobile Nav */
  mobileNavToggle.addEventListener("click", () => {
    if (mastheadNode.classList.contains("main-menu-expanded")) {
      closeMainMenu();
      closeMegaMenu();
    } else {
      openMainMenu();
    }
  });
  // function to open the main navigation:
  function openMainMenu() {
    pageNode.classList.add("no-scroll");
    mastheadNode.classList.add("main-menu-expanded");
    mobileNavToggle.setAttribute("aria-expanded", "true");
    mobileNavToggleA11YText.innerHTML = "Stäng huvudmeny";
  }
  // function to close the main navigation:
  function closeMainMenu() {
    pageNode.classList.remove("no-scroll");
    mastheadNode.classList.remove("main-menu-expanded");
    mobileNavToggle.setAttribute("aria-expanded", "false");
    mobileNavToggleA11YText.innerHTML = "Öppna huvudmeny";
  }
  const servicesMegaMenuTrigger = document.querySelector("#megaMenuTrigger");
  const servicesMegaMenu = document.querySelector("#servicesMegaMenu");
  /* const servicesMegaMenuA11yText = servicesMegaMenuTrigger.querySelector(
    ".screen-reader-text"
  ); */

  /* const menuOverlay = document.querySelector(".menu-overlay"); */

  servicesMegaMenuTrigger.addEventListener("click", () => {
    if (mastheadNode.classList.contains("mega-menu-expanded")) {
      closeMegaMenu();
    } else {
      openMegaMenu();
    }
  });
  const openMegaMenu = () => {
    mastheadNode.classList.add("mega-menu-expanded");
    // Slide the menu down from top:
    servicesMegaMenu.style.maxHeight = servicesMegaMenu.scrollHeight + "px";
    /* menuOverlay.classList.add("active"); */
    megaMenuTrigger.setAttribute("aria-expanded", "true");
    // servicesMegaMenuA11yText.innerHTML = "Stäng huvudmeny";
  };
  // Create a function to close the mega menu:
  const closeMegaMenu = () => {
    mastheadNode.classList.remove("mega-menu-expanded");
    servicesMegaMenu.style.maxHeight = "0" + "px";
    /*     menuOverlay.classList.remove("active"); */
    servicesMegaMenuTrigger.setAttribute("aria-expanded", "false");
    // servicesMegaMenuA11yText.innerHTML = "Öppna huvudmeny";
  };

  /*
   * A11y functions for mega menu.
   */
  // Close mega menu if we click outside of it (on the overlay):
  /*   menuOverlay.addEventListener("click", () => {
    closeMegaMenu();
  }); */

  // a11y keyboard navigation for mega menu:
  const megaMenuLinks = document.querySelectorAll("#servicesMegaMenu a");
  // Check if we tab out from the last link in the mega menu
  const lastMegaMenuLink = megaMenuLinks[megaMenuLinks.length - 1];
  lastMegaMenuLink.addEventListener("keydown", (e) => {
    if (e.key === "Tab") {
      closeMegaMenu();
    }
  });
  // Check if we tab out backwards from the first link in the mega menu
  const firstMegaMenuLink = megaMenuLinks[0];
  firstMegaMenuLink.addEventListener("keydown", (e) => {
    if (e.key === "Tab" && e.shiftKey) {
      closeMegaMenu();
    }
  });
  // if we tab backwards from the trigger, close the mega menu
  servicesMegaMenuTrigger.addEventListener("keydown", (e) => {
    if (e.key === "Tab" && e.shiftKey) {
      closeMegaMenu();
    }
  });
  // If we hit escape key, close the mega menu
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      closeMegaMenu();
    }
  });
  // If the window rezises below 768px, close main navigation and the mega menu
  window.addEventListener("resize", () => {
    if (window.innerWidth < 1328) {
      closeMainMenu();
      closeMegaMenu();
    }
  });
}
